import * as React from "react"
import { graphql } from "gatsby"
import moment from 'moment';
import Layout from "../../components/layout";
import Toolbar from '../../components/Toolbar';
import FieldValue from "../../components/FieldValue";
import DropDownList from "../../components/DropDownList";
import HelpScreen from "../../components/HelpScreen";
import Image from "../../components/Image";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { 
  useTheme, Avatar, Zoom, Paper, Button, IconButton,
  TextField, Snackbar, Dialog, DialogTitle, DialogContent,
  DialogActions, Slide, Typography, Fab, Popover, Tooltip
} from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import MuiAlert from '@material-ui/lab/Alert';
import MuiMarkdown from 'mui-markdown';
import { isMobile, osName } from 'react-device-detect';
import * as MUIIcons from '@material-ui/icons';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import clsx from 'clsx';

import '@inovua/reactdatagrid-community/base.css';
import '@inovua/reactdatagrid-community/theme/blue-light.css';
import '@inovua/reactdatagrid-community/theme/blue-dark.css';
import '@inovua/reactdatagrid-community/theme/green-light.css';
import '@inovua/reactdatagrid-community/theme/green-dark.css';
import useCommonStyles from '../../assets/style/commonStyle';
// import useUserMgtStyles from '../../assets/style/usermgtStyle';

import { connect } from 'react-redux';
import * as loggingActions from '../../redux/actions/loggingActions';
import Api from '../../lib/api';
import Helper from '../../lib/helper';
import UIHelper from '../../lib/uiHelper';
import UserHelper from '../../lib/userHelper';

const gridStyle = { minHeight: 550 }

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RecLock(props) { 
  return(
    <div {...props}>
      <MUIIcons.Lock style={{width: 16, height: 16}} />
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const userValidationInitState = {
  avatar: {error: false, touched: false, message: ''},
  description: {error: false, touched: false, message: ''},
  email: {error: false, touched: false, message: ''},
  first_name: {error: false, touched: false, message: ''},
  last_name: {error: false, touched: false, message: ''},
  language: {error: false, touched: false, message: ''},
  location: {error: false, touched: false, message: ''},
  role: {error: false, touched: false, message: ''},
  status: {error: false, touched: false, message: ''},
  theme: {error: false, touched: false, message: ''},
  title: {error: false, touched: false, message: ''}
};

const userInviteValidationInitState = {
  email: {error: false, touched: false, message: ''},
  role: {error: false, touched: false, message: ''}
};

const isBrowser = typeof window !== 'undefined';

const UserMgtPage = (props) => {
  const {t} = useTranslation('usermgt');
  const commonClasses = useCommonStyles();
  // const classes = useUserMgtStyles();
  const theme = useTheme();
  const locale = props.ui.get('lang');
  const auth = Helper.getStateAsJSObj(props.session, 'auth', null);
  const sysCodes = Helper.getStateAsJSObj(props.system, 'setting.data.codes', []);
  const sysRoles = Helper.getStateAsJSObj(props.system, 'setting.data.roles', []);
  const appDetail = Helper.getStateAsJSObj(props.system, 'setting.data.application', null);
  const sysConfig = Helper.getStateAsJSObj(props.system, 'setting.data.configs', []);
  const loginSecurityConfig = sysConfig.find(item => item.category === 'authentication' && item.identifier === 'login.security');
  const useMgtConfig = sysConfig.find(item => item.category === 'system' && item.identifier === 'system.usermgt');
  const logConfig = sysConfig.find(item => item.category === 'system' && item.identifier === 'system.logging');
  const logEnabled = Helper.carefullyGetValue(logConfig, 'value.log_enable'.split('.'), false);
  const consoleLogEnabled = Helper.carefullyGetValue(logConfig, 'value.console_log_enable'.split('.'), false);
  var myRoleLevel = UserHelper.getRoleLevel(auth.data?.role, sysRoles);
  
  const [busy, setBusy] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const [showNoRightWarning, setShowNoRightWarning] = React.useState(false);
  const [showResetPwd, setShowResetPwd] = React.useState(false);
  const [resetPwdSent, setResetPwdSent] = React.useState(false);
  const [resetPwdError, setResetPwdError] = React.useState('');
  const [showDisableTFA, setShowDisableTFA] = React.useState(false);
  const [disableTFASuccess, setDisableTFASuccess] = React.useState(false);
  const [disableTFAError, setDisableTFAError] = React.useState('');
  const [showUserDelete, setShowUserDelete] = React.useState(false);
  const [showUserInvite, setShowUserInvite] = React.useState(false);
  const [fabAnchorEl, setFabAnchorEl] = React.useState(null);
  const [gridRef, setGridRef] = React.useState(null);
  const [isMySelf, setIsMySelf] = React.useState(false);
  
  const [updateStatus, setUpdateStatus] = React.useState(0); // 0 - no action, 1 - Update success, 2 - Update failed
  const [deleteStatus, setDeleteStatus] = React.useState(0); // 0 - no action, 1 - Delete success, 2 - Delete failed
  const [inviteStatus, setInviteStatus] = React.useState(0); // 0 - no action, 1 - Invite success, 2 - Invite failed, 3 - Already invited or exist in system
  const [userFormMode, setUserFormMode] = React.useState('view');
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedUserAvatar, setSelectedUserAvatar] = React.useState(null);
  const [filterValue, setFilterValue] = React.useState([
    { name: 'email', operator: 'contains', type: 'string', value: '' },
    { name: 'first_name', operator: 'contains', type: 'string', value: '' },
    { name: 'last_name', operator: 'contains', type: 'string', value: '' },
    { name: 'location', operator: 'contains', type: 'string', value: '' },
    { name: 'title', operator: 'contains', type: 'string', value: '' },
    { name: 'status', operator: 'eq', type: 'string', value: '' },
    { name: 'last_access', operator: 'afterOrOn', type: 'date', value: '' }
  ]);
  // const [activeCell, setActiveCell] = React.useState(null);
  const dataGridTheme = `${theme.palette.type === 'dark' ? 'green' : 'blue'}-${theme.palette.type}`;
  const columns = [
    { name: 'avatar', header: t('datagrid.usermgt.header.avatar'), textAlign: 'center', textVerticalAlign: 'middle', defaultWidth: 80,
      sortable: false,
      showColumnMenuTool: false,
      render: ({value, data})=> {
        let recRoleLevel = UserHelper.getRoleLevel(data.role, sysRoles);
        return Helper.stringHasValue(value) ?
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {/* Add ts in the URL to prevent browser image caching */}
            <Avatar alt="" style={{width: 30, height: 30}} src={`${process.env.GATSBY_API_URL}assets/${value}?access_token=${auth.data?.access_token}&width=30&height=30&fit=cover&ts=${new Date().getTime()}`} />
            {
              myRoleLevel > recRoleLevel &&
              <RecLock className={commonClasses.dataGridRecLock} />
            }
          </div>
          :
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <MUIIcons.AccountCircle fontSize='large' />
            {
              myRoleLevel > recRoleLevel &&
              <RecLock className={commonClasses.dataGridRecLock} />
            }
          </div>
      }
    },
    { name: 'email', defaultFlex: 3, header: t('datagrid.usermgt.header.email'), 
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      }
    },
    { name: 'first_name', defaultFlex: 2, header: t('datagrid.usermgt.header.first_name'), 
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      }
    },
    { name: 'last_name', defaultFlex: 2, header: t('datagrid.usermgt.header.last_name'), 
      defaultVisible: !isMobile,
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      } 
    },
    { name: 'location', defaultFlex: 2, header: t('datagrid.usermgt.header.location'), 
      defaultVisible: !isMobile,
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      }
    },
    { name: 'title', defaultFlex: 3, header: t('datagrid.usermgt.header.title'), 
      defaultVisible: !isMobile,
      filterEditorProps: {
        placeholder: t('datagrid.filter.caseSensitiveSearch')
      }
    },
    { name: 'status', defaultFlex: 1, textAlign: 'center', header: t('datagrid.usermgt.header.status'),
      defaultVisible: !isMobile,  
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: t('datagrid.usermgt.filter.status.all'),
        dataSource: sysCodes.filter(item => item.category === 'user.status').map(item => ({id: item.code, label: item.text}))
      },
      render: ({value}) => {
        let code = sysCodes.find(item => item.code === value && item.category === 'user.status');
        if(Helper.isNotNullAndUndefined(code)) {
          return code.text;
        }
        else {
          return value;
        }
      }
    },
    { name: 'last_access', defaultFlex: 2, type: 'date', header: t('datagrid.usermgt.header.last_access'), 
      defaultVisible: !isMobile,
      // need to specify dateFormat
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      filterEditor: DateFilter,
      filterEditorProps: (props, { index }) => {
        // for range and notinrange operators, the index is 1 for the before field
        return {
          dateFormat: 'DD-MM-YYYY hh:mm:ss',
          placeholder: index === 1 ? t('datagrid.usermgt.filter.last_access.end') : t('datagrid.usermgt.filter.last_access.start')
        }
      },
      render: ({ value, cellProps: { dateFormat } }) => 
        Helper.stringHasValue(value) ? moment(value).format(dateFormat) : '',
    }
  ];


  
  React.useEffect(() => {
    const onKeyDown = (event) => {
      // console.log('onKeyDown: ', event);
      let key = Helper.carefullyGetValue(event, ['key'], '');
      let hasModifierKey = osName === 'Mac OS' ? event.metaKey : event.ctrlKey;
      switch (key.toLowerCase()) {
        case 'f1':
          // Show context help screen
          setShowHelp(value => !value);
          event.preventDefault();
          break;

        case 'escape': // Esc key to cancel edit
          if(!showHelp && Helper.isNotNullAndUndefined(selectedUser)) {
            if(userFormMode === 'view') {
              closeSelectedUser();
            }
    
            if(userFormMode === 'edit') {
              editUserCancel();
            }
            event.preventDefault();
          }
          break;

        case 'e': // Ctrl+E for Windows, Cmd+E for Mac, to enter edit mode
          if(!showHelp && Helper.isNotNullAndUndefined(selectedUser) && userFormMode === 'view' && hasModifierKey && useMgtConfig?.value?.allow_edit) {
            editUser();
            event.preventDefault();
          }
          break;

        case 's': // Ctrl+S for Windows, Cmd+S for Mac, to save the changes
          if(!showHelp && Helper.isNotNullAndUndefined(selectedUser) && userFormMode === 'edit' && hasModifierKey && useMgtConfig?.value?.allow_edit) {
            updateUser();
            event.preventDefault();
          }
          break;

        case 'i': // Ctrl+I for Windows, Cmd+I for Mac, to invite user
          if(!showHelp && !Helper.isNotNullAndUndefined(selectedUser) && hasModifierKey && useMgtConfig?.value?.allow_invite) {
            // Show the invite dialog
            userInviteFabOnClick();
            event.preventDefault();
          }
          break;

        case 'c': // Ctrl+C for Windows, Cmd+C for Mac, to create new user
          if(!showHelp && !Helper.isNotNullAndUndefined(selectedUser) && hasModifierKey && useMgtConfig?.value?.allow_create) {
            // Show new user form
            userCreateFabOnClick();
            event.preventDefault();
          }
          break;

        case 'd': // Ctrl+D for Windows, Cmd+D for Mac, to delete user
          if(!showHelp && Helper.isNotNullAndUndefined(selectedUser) && userFormMode === 'view' && hasModifierKey && useMgtConfig?.value?.allow_delete) {
            if(!hasAccessRight()) {
              setShowNoRightWarning(true);
            }
            else {
              setShowUserDelete(true);
            }
            event.preventDefault();
          }
          break;

        case 'delete': // Delete key, to delete user
          if(!showHelp && Helper.isNotNullAndUndefined(selectedUser) && userFormMode === 'view' && useMgtConfig?.value?.allow_delete) {
            if(!hasAccessRight()) {
              setShowNoRightWarning(true);
            }
            else {
              setShowUserDelete(true);
            }
            event.preventDefault();
          }
          break;

        case 'g': // Ctrl+G for Windows, Cmd+G for Mac, to make data grid in focus
          if(!showHelp && !Helper.isNotNullAndUndefined(selectedUser)) {
            gridRef?.current && gridRef.current.focus();
            event.preventDefault();
          }
          break;

        case 'p': // Ctrl+P for Windows, Cmd+P for Mac, to reset password
          if(!showHelp && Helper.isNotNullAndUndefined(selectedUser) && userFormMode === 'view' && hasModifierKey && useMgtConfig?.value?.allow_edit) {
            if(!hasAccessRight()) {
              setShowNoRightWarning(true);
            }
            else {
              setShowResetPwd(true);
            }
            event.preventDefault();
          }
          break;

        case '2':
          if(!showHelp && Helper.isNotNullAndUndefined(selectedUser) && userFormMode === 'view' && hasModifierKey && useMgtConfig?.value?.allow_edit) {
            if(!hasAccessRight()) {
              setShowNoRightWarning(true);
            }
            else {
              Helper.stringHasValue(selectedUser?.tfa_secret) && setShowDisableTFA(true);
            }
            event.preventDefault();
          }
          break;
        
        default:
          break;
      }
      
    };

    if(isBrowser) {
      document.addEventListener("keydown", onKeyDown);
    }

    return () => {
      if(isBrowser) {
        document.removeEventListener("keydown", onKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, userFormMode, showHelp, useMgtConfig]); // Place the selectedUser, userFormMode in the depenency so that when ever the these two state changed, the event listender will get the latest states.

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    const sortFields = [];
    // If have sort info
    if(Helper.isNotNullAndUndefined(sortInfo)) {
      if(Array.isArray(sortInfo)) {
        sortInfo.forEach(item => {
          sortFields.push(`${item.dir === 1 ? '' : '-'}${item.id}`)
        });
      }
      else {
        sortFields.push(`${sortInfo.dir === 1 ? '' : '-'}${sortInfo.id}`);
      }
    }
  
    let apiFilter = Helper.populateSearchFilter(filterValue);
    
    return Api.userGetList({
        meta: 'filter_count', // Get the total record count
        filter: apiFilter,
        sort: sortFields,
        offset: skip,
        limit: limit
      })
      .then(result => {
        let data = result.data.map(item => {
          item.last_access = Helper.stringHasValue(item.last_access) ? moment(item.last_access).toDate() : null;
          return item;
        });
        return Promise.resolve({ data: data, count: result?.meta.filter_count });
      })
      .catch(err => {
        if(logEnabled) {
          err.message += ' - Api.userGetList Error @ /system/usermgt.js';
          props.dispatch(
            loggingActions.loggingNew(
              Helper.populateLoggingDetail(err, {
                session: props.session.toJS()
              })
            )
          );
        }
  
        consoleLogEnabled && console.error('userUpdate Error: ', err);
        return Promise.reject(err);
      })
  }

  const dataSource = React.useCallback(loadData, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onGridKeyDown = React.useCallback(event => {
    if(event.key === 'Enter' && gridRef?.current && gridRef.current.activeRowRef 
       && gridRef.current.activeRowRef.current && gridRef.current.activeRowRef.current.instance 
       && gridRef.current.activeRowRef.current.instance.props
       && gridRef.current.activeRowRef.current.instance.props.data) {
      openSelectedUser(gridRef.current.activeRowRef.current.instance.props.data);
    }
  }, [gridRef]);
  
  const userFormRef = React.useRef(null);
  const [userValidation, setUserValidation] = React.useState(userValidationInitState);
  const userFields = [
    {field: 'avatar', required: false, regExp: "", type: 'string', label: 'userform.avatar'},
    {field: 'description', required: false, regExp: '', type: 'string', label: 'userform.description'},
    {field: 'email', required: true, regExp: UIHelper.regExp.email, type: 'string', label: 'userform.email'},
    {field: 'first_name', required: true, regExp: '', type: 'string', label: 'userform.first_name'},
    {field: 'last_name', required: false, regExp: '', type: 'string', label: 'userform.last_name'},
    {field: 'language', required: false, regExp: '', type: 'string', label: 'userform.language'},
    {field: 'location', required: false, regExp: '', type: 'string', label: 'userform.location'},
    {field: 'role', required: true, regExp: '', type: 'string', label: 'userform.role'},
    {field: 'status', required: true, regExp: '', type: 'string', label: 'userform.status'},
    {field: 'theme', required: false, regExp: '', type: 'string', label: 'userform.theme'},
    {field: 'title', required: false, regExp: '', type: 'string', label: 'userform.title'}
  ];

  const userInviteFormRef = React.useRef(null);
  const [userInviteValidation, setUserInviteValidation] = React.useState(userInviteValidationInitState);
  const userInviteFields = [
    {field: 'email', required: true, regExp: UIHelper.regExp.email, type: 'string', label: 'userform.email'},
    {field: 'role', required: true, regExp: '', type: 'string', label: 'userform.role'}
  ];

  const userFormUI = () => {
    let selectedUserRoleLevel = UserHelper.getRoleLevel(selectedUser?.role, sysRoles);
    return(
    <form ref={userFormRef} className={commonClasses.flexColumn} onSubmit={(event) => {
        updateUser();
        event.preventDefault();
      }}
    >
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        {
          userFormMode === 'view' ?
          <>
            <FieldValue containerStyle={{flex: 2, minWidth: 300}} label={t('userform.email')} value={Helper.carefullyGetValue(selectedUser, ['email'], '')} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.first_name')} value={Helper.carefullyGetValue(selectedUser, ['first_name'], '')} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.last_name')} value={Helper.carefullyGetValue(selectedUser, ['last_name'], '')} />
          </>
          :
          <>
            <TextField id="email" label={t('userform.email')} 
              style={{flex: 2, minWidth: 300, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(selectedUser, ['email'], '')} 
              error={userValidation.email.error}
              helperText={userValidation.email.message}
              onBlur={(event) => Helper.validateFormField('email', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
            <TextField id="first_name" label={t('userform.first_name')} 
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(selectedUser, ['first_name'], '')} 
              error={userValidation.first_name.error}
              helperText={userValidation.first_name.message}
              onBlur={(event) => Helper.validateFormField('first_name', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
            <TextField id="last_name" label={t('userform.last_name')} 
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(selectedUser, ['last_name'], '')} 
              error={userValidation.last_name.error}
              helperText={userValidation.last_name.message}
              onBlur={(event) => Helper.validateFormField('last_name', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
          </>
        }
      </div>
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        {
          userFormMode === 'view' ?
          <>
            <FieldValue containerStyle={{flex: 2, minWidth: 300}} label={t('userform.title')} value={Helper.carefullyGetValue(selectedUser, ['title'], '')} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.location')} value={Helper.carefullyGetValue(selectedUser, ['location'], '')} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.language')} value={UIHelper.getCodeText(selectedUser?.language, sysCodes.filter(item => item.category === 'ui.language'))} />
          </>
          :
          <>
            <TextField id="title" label={t('userform.title')} 
              style={{flex: 2, minWidth: 300, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(selectedUser, ['title'], '')} 
              error={userValidation.title.error}
              helperText={userValidation.title.message}
              onBlur={(event) => Helper.validateFormField('title', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
            <TextField id="location" label={t('userform.location')} 
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              variant="outlined" 
              defaultValue={Helper.carefullyGetValue(selectedUser, ['location'], '')} 
              error={userValidation.location.error}
              helperText={userValidation.location.message}
              onBlur={(event) => Helper.validateFormField('location', event.target.value, userFields, userValidation, setUserValidation, t)}
            />
            <DropDownList id="language" 
              variant="outlined"  
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              labelText={t('userform.language')} 
              defaultValue={Helper.carefullyGetValue(selectedUser, ['language'], '')}
              options={sysCodes.filter(item => item.category === 'ui.language')}
              error={userValidation.language.error}
              helperText={userValidation.language.message}
            />
          </>
        }
      </div>
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        {
          userFormMode === 'view' ?
          <>
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.theme')} value={UIHelper.getCodeText(selectedUser?.theme, sysCodes.filter(item => item.category === 'ui.theme'))} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.role')} value={UIHelper.getRoleText(selectedUser?.role, sysRoles, locale)} />
            <FieldValue containerStyle={{flex: 1, minWidth: 200}} label={t('userform.status')} value={UIHelper.getCodeText(selectedUser?.status, sysCodes.filter(item => item.category === 'user.status'))} />
          </>
          :
          <>
            <DropDownList id="theme" 
              variant="outlined"  
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              labelText={t('userform.theme')} 
              defaultValue={Helper.carefullyGetValue(selectedUser, ['theme'], '')}
              options={sysCodes.filter(item => item.category === 'ui.theme')}
              error={userValidation.theme.error}
              helperText={userValidation.theme.message}
            />
            <DropDownList id="role" 
              variant="outlined"  
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              labelText={t('userform.role')} 
              defaultValue={Helper.carefullyGetValue(selectedUser, ['role'], '')}
              options={
                sysRoles
                .sort((a, b) => (a.value?.level > b.value?.level ? -1 : (a.value?.level < b.value?.level ? 1 : 0)))
                .filter(item => item.value?.level >= myRoleLevel)
                .map(item => ({code: item.role, text: UIHelper.getRoleText(item.role, sysRoles, locale)}))
              }
              error={userValidation.role.error}
              helperText={userValidation.role.message}
            />
            <DropDownList id="status" 
              variant="outlined"  
              style={{flex: 1, minWidth: 200, marginBottom: 10}}
              labelText={t('userform.status')} 
              defaultValue={Helper.carefullyGetValue(selectedUser, ['status'], '')}
              options={sysCodes.filter(item => item.category === 'user.status')}
              error={userValidation.status.error}
              helperText={userValidation.status.message}
            />
          </>
        }
      </div>
      {
        !isMobile && userFormMode === 'view' && 
        Helper.isNotNullAndUndefined(selectedUserRoleLevel) && 
        myRoleLevel <= selectedUserRoleLevel &&
        <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)} style={{marginTop: 20}}>
          <Button variant="outlined" color='secondary' 
            disabled={!useMgtConfig?.value?.allow_edit}
            startIcon={<MUIIcons.VpnKey />} 
            onClick={_ => setShowResetPwd(true)}
          >
            {t('button.resetpwd')}
          </Button>
          {
            loginSecurityConfig.value?.enable_tfa &&
            <Button variant="outlined" color='secondary' startIcon={<MUIIcons.VerifiedUser />} 
              disabled={!Helper.stringHasValue(selectedUser?.tfa_secret) || !useMgtConfig?.value?.allow_edit}
              onClick={_ => setShowDisableTFA(true)}  
            >
              {t('button.disabletfa')}
            </Button>
          }
        </div>
      }
    </form>);
  }

  const userFormPhoto = <div className={clsx(commonClasses.flexRow, commonClasses.defaultPadding)} style={isMobile ? {position: 'relative', justifyContent: 'center'} : {position: 'relative', flex: 2, justifyContent: 'center'}}>
    {
      userFormMode === 'view' && Helper.stringHasValue(selectedUser?.avatar) &&
      <Avatar alt="" className={commonClasses.avatar} src={`${process.env.GATSBY_API_URL}assets/${Helper.carefullyGetValue(selectedUser, ['avatar'], '')}?access_token=${auth.data?.access_token}&width=300&height=300&fit=cover&ts=${new Date().getTime()}`} />
    }
    {
      userFormMode === 'view' && !Helper.stringHasValue(selectedUser?.avatar) &&
      <MUIIcons.AccountCircle className={commonClasses.avatar} />
    }
    {
      (userFormMode === 'edit' && Helper.isNotNullAndUndefined(selectedUserAvatar)) &&
      <>
        {
          typeof selectedUserAvatar === 'string' ?
          <Avatar alt="" className={commonClasses.avatar} src={`${process.env.GATSBY_API_URL}assets/${selectedUserAvatar}?access_token=${auth.data?.access_token}&width=300&height=300&fit=cover&ts=${new Date().getTime()}`} />
          :
          <div className={commonClasses.avatar} style={{overflow: 'clip', borderRadius: isMobile ? 125 : 150}}>
            <Image width={isMobile ? 250 : 300} height={isMobile ? 250 : 300} 
              objectFit={'fill'}
              src={selectedUserAvatar} 
            />
          </div>
        }
      </>
    }
    {
      (userFormMode === 'edit' && !Helper.isNotNullAndUndefined(selectedUserAvatar)) &&
      <DropzoneArea
        filesLimit={1}
        classes={{
          root: commonClasses.avatarWithBorder
        }}
        acceptedFiles={['image/*']}
        dropzoneText={t('userform.avatar.dropzone')}
        maxFileSize={10485760} // 10mb
        showPreviews={false}
        showPreviewsInDropzone={false}
        onChange={files => {
          setSelectedUserAvatar(Helper.arrayHasItem(files) ? files[0] : null)
        }}
        initialFiles={null}
      />
    }
    {
      userFormMode === 'edit' && Helper.isNotNullAndUndefined(selectedUserAvatar) &&
      <IconButton aria-label="delete" 
        style={{position: 'absolute', right: 10, top: 10, backgroundColor: '#fff3', width: 50, height: 50, borderRadius: 25}}
        onClick={() => {
          setSelectedUserAvatar(null);
        }}
      >
        <MUIIcons.Delete />
      </IconButton>
    }
  </div>

  const hasAccessRight = () => {
    let haveAccess = false;

    if(Helper.isNotNullAndUndefined(selectedUser)) {
      let recRoleLevel = UserHelper.getRoleLevel(selectedUser.role, sysRoles);
      haveAccess = Helper.isNotNullAndUndefined(recRoleLevel) && myRoleLevel <= recRoleLevel;
    }

    return haveAccess;
  }

  const editUser = () => {
    if(Helper.isNotNullAndUndefined(selectedUser)) {
      if(!hasAccessRight()) {
        setShowNoRightWarning(true);
      }
      else {
        setUserFormMode('edit');
        setSelectedUserAvatar(selectedUser.avatar);
        setUserValidation(userValidationInitState);
      }
    }
  }

  const editUserCancel = () => {
    if(Helper.stringHasValue(selectedUser.id)) {
      setUserFormMode('view');
      setSelectedUserAvatar(null);
    }
    else {
      setUserFormMode('view');
      setSelectedUser(null);
      setSelectedUserAvatar(null);
    }
  }

  const openSelectedUser = (data) => {
    setUserValidation(userValidationInitState);
    setSelectedUser(data);
    setSelectedUserAvatar(data?.avatar);
    let recRoleLevel = UserHelper.getRoleLevel(data?.role, sysRoles);
    setShowNoRightWarning(Helper.isNotNullAndUndefined(recRoleLevel) && myRoleLevel > recRoleLevel);
    setIsMySelf(Helper.stringHasValue(auth.data?.id) && Helper.stringHasValue(data?.id) && auth.data?.id === data?.id);
  } 

  const closeSelectedUser = () => {
    setSelectedUser(null);
    setSelectedUserAvatar(null);
  }

  const toolbarActionButtons = () => {
    let selectedUserRoleLevel = UserHelper.getRoleLevel(selectedUser?.role, sysRoles);
    return(
      isMobile ?
      <div className={commonClasses.flexFullRow} style={{gap: 10, marginLeft: 10, marginRight: 10, justifyContent: 'flex-end'}}>
        {
          userFormMode === 'view' ?
          <>
            {
              (Helper.isNotNullAndUndefined(selectedUserRoleLevel) && myRoleLevel <= selectedUserRoleLevel) &&
              <div className={commonClasses.flexRow} style={{flex: 1, gap: 10}}>
                <IconButton disabled={!useMgtConfig?.value?.allow_edit}
                  className={useMgtConfig?.value?.allow_edit ? commonClasses.toolbarActionButtonMobile : commonClasses.toolbarActionButtonMobileDisabled} 
                  onClick={_ => setShowResetPwd(true)}>
                  <MUIIcons.VpnKey />
                </IconButton>
                {
                  loginSecurityConfig.value?.enable_tfa &&
                  <IconButton disabled={!Helper.stringHasValue(selectedUser.tfa_secret) || !useMgtConfig?.value?.allow_edit} 
                    className={(Helper.stringHasValue(selectedUser.tfa_secret) && useMgtConfig?.value?.allow_edit) ? commonClasses.toolbarActionButtonMobile : commonClasses.toolbarActionButtonMobileDisabled} 
                    onClick={_ => setShowDisableTFA(true)}>
                    <MUIIcons.VerifiedUser />
                  </IconButton>
                }
              </div>
            }
            <div className={commonClasses.flexRow} style={{flex: 2, gap: 10, justifyContent: 'flex-end'}}>
              {
                (Helper.isNotNullAndUndefined(selectedUserRoleLevel) && myRoleLevel <= selectedUserRoleLevel) &&
                <>
                  {
                    useMgtConfig?.value?.allow_edit &&
                    <IconButton className={commonClasses.toolbarActionSecondaryButtonMobile} onClick={_ => editUser()}>
                      <MUIIcons.EditRounded />
                    </IconButton>
                  }
                  {
                    useMgtConfig?.value?.allow_delete && !isMySelf &&
                    <IconButton className={commonClasses.toolbarActionWarningButtonMobile} onClick={_ => setShowUserDelete(true)}>
                      <MUIIcons.Delete />
                    </IconButton>
                  }
                </>
              }
              <IconButton className={commonClasses.toolbarActionButtonMobile} onClick={_ => closeSelectedUser()}>
                <MUIIcons.Close />
              </IconButton>
            </div>
          </>
          :
          <>
            {
              ((Helper.isNotNullAndUndefined(selectedUserRoleLevel) && myRoleLevel <= selectedUserRoleLevel) || !Helper.stringHasValue(selectedUser.id)) && useMgtConfig?.value?.allow_edit &&
              <IconButton className={commonClasses.toolbarActionPrimaryButtonMobile} onClick={_ => updateUser()}>
                <MUIIcons.Save />
              </IconButton>
            }
            <IconButton className={commonClasses.toolbarActionButtonMobile} onClick={_ => editUserCancel()}>
              <MUIIcons.Cancel />
            </IconButton>
          </>
        }
      </div>
      :
      <div className={commonClasses.flexRow} style={{gap: 10, marginRight: 10, alignItems: 'center'}}>
        {
          userFormMode === 'view' ?
          <>
            <Tooltip 
              TransitionComponent={Zoom} 
              arrow
              disableFocusListener
              enterTouchDelay={0}
              placement={'left'}
              title={
                <React.Fragment>
                  <Typography color="inherit">{t(`tooltip.${userFormMode}.${loginSecurityConfig?.value?.enable_tfa ? 'tfa_enabled' : 'tfa_disabled'}`).replace(/{modifier}/g, UIHelper.getModifierKey())}</Typography>
                </React.Fragment>
              }
            >
              <MUIIcons.HelpOutline />
            </Tooltip>
            {
              (Helper.isNotNullAndUndefined(selectedUserRoleLevel) &&  myRoleLevel <= selectedUserRoleLevel) &&
              <>
                {
                  useMgtConfig?.value?.allow_edit &&
                  <Button variant='outlined' color='secondary' startIcon={<MUIIcons.EditRounded />} onClick={_ => editUser()}>
                    {t('button.edit')}
                  </Button>
                }
                {
                  useMgtConfig?.value?.allow_delete && !isMySelf &&
                  <Button variant='outlined' className={commonClasses.deleteOutlinedButton} startIcon={<MUIIcons.Delete />} onClick={_ => setShowUserDelete(true)}>
                    {t('button.delete')}
                  </Button>
                }
              </>
            }
            <Button variant='outlined' color='inherit' startIcon={<MUIIcons.Close />} onClick={_ => closeSelectedUser()}>
              {t('button.close')}
            </Button>
          </>
          :
          <>
            <Tooltip 
              TransitionComponent={Zoom} 
              arrow
              disableFocusListener
              enterTouchDelay={0}
              placement={'left'}
              title={
                <React.Fragment>
                  <Typography color="inherit">{t(`tooltip.${userFormMode}`).replace(/{modifier}/g, UIHelper.getModifierKey())}</Typography>
                </React.Fragment>
              }
            >
              <MUIIcons.HelpOutline />
            </Tooltip>
            {
              ((Helper.isNotNullAndUndefined(selectedUserRoleLevel) && myRoleLevel <= selectedUserRoleLevel) || !Helper.stringHasValue(selectedUser.id)) && useMgtConfig?.value?.allow_edit &&
              <Button variant='outlined' color='primary' startIcon={<MUIIcons.Save />} onClick={_ => updateUser()}>
                {t('button.save')}
              </Button>
            }
            <Button variant='outlined' color='inherit' startIcon={<MUIIcons.Cancel />} onClick={_ => editUserCancel()}>
              {t('button.cancel')}
            </Button>
          </>
        }
      </div>
    );
  }
  
  const updateUser = async () => {
    setUpdateStatus(0);
    try {
      let valid = Helper.validateFormRef(userFormRef, userFields, userValidation, setUserValidation, t);
      if(valid) {
        setBusy(true);
        let formData = Helper.deepCopy(selectedUser);

        // Only upload the photo if the selectedUserAvatar is not null and is an file object
        if(Helper.isNotNullAndUndefined(selectedUserAvatar) && typeof selectedUserAvatar !== 'string') {
          let avatar = await Api.fileSet(selectedUser.avatar, selectedUserAvatar);
          if(Helper.isNotNullAndUndefined(avatar)) {
            formData.avatar = avatar.id;
          }
        }

        // If selectedUserAvatar is null and selectedUser.avatar have value, this mean the admin is 
        // removing the profile photo for the selected user.
        if(!Helper.isNotNullAndUndefined(selectedUserAvatar) && Helper.stringHasValue(selectedUser.avatar)) {
          await Api.fileDelete(selectedUser.avatar);
          formData.avatar = null;
        }

        // Update the formData var with the actual data in the from
        userFields.forEach(item => {
          if(Helper.isNotNullAndUndefined(userFormRef.current.elements[item.field])) {
            formData[item.field] = Helper.carefullyGetValue(userFormRef, `current.elements.${item.field}.value`.split('.'), '');
          }
        });

        let result = await (Helper.stringHasValue(formData.id) ? Api.userUpdate(formData) : Api.userCreate(formData));
        setBusy(false);
        if(result) {
          gridRef.current?.reload();
          setUserFormMode('view');
          setUpdateStatus(1);
          setSelectedUser(null);
          setSelectedUserAvatar(null);
        }
        else {
          setUpdateStatus(2);
        }
      }
    } catch (error) {
      setBusy(false);
      setUpdateStatus(2);
      if(logEnabled) {
        error.message += ' - updateUser Error @ /system/usermgt.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }

      consoleLogEnabled && console.error('userUpdate Error: ', error);
    }
    
  }

  const deleteUser = async () => {
    setDeleteStatus(0);
    try {
        setBusy(true);
        
        // Delete the user avatar if any
        if(Helper.stringHasValue(selectedUser.avatar)) {
          await Api.fileDelete(selectedUser.avatar);
        }

        await Api.userDelete(selectedUser.id);
        setBusy(false);
        gridRef.current?.reload();
        setUserFormMode('view');
        setDeleteStatus(1);
        setShowUserDelete(false);
        setSelectedUser(null);
    } catch (error) {
      setBusy(false);
      setDeleteStatus(2);
      setShowUserDelete(false);
      if(logEnabled) {
        error.message += ' - deleteUser Error @ /system/usermgt.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }
      consoleLogEnabled && console.error('userDelete Error: ', error);
    }
    
  }

  const passwordReset = (email) => {
    setBusy(false);
    setResetPwdSent(false);
    setResetPwdError('');
    let resetURL = Helper.carefullyGetValue(appDetail, ['url'], '');

    if(!Helper.stringHasValue(email)) {
      setResetPwdError(t('userform.pwdreset.error.no_email'));
      setShowResetPwd(false);
      return;
    }

    if(Helper.stringHasValue(resetURL)) {
      setBusy(true);
      Api.passwordResetRequest(email, `${resetURL}pwdreset`)
      .then(_ => {
        setResetPwdSent(true);
        setShowResetPwd(false);
        setBusy(false);
      })
      .catch(err => {
        setResetPwdError(err.message);
        setShowResetPwd(false);
        setBusy(false);
        if(logEnabled) {
          err.message += ' - Api.passwordResetRequest Error @ /system/usermgt.js';
          props.dispatch(
            loggingActions.loggingNew(
              Helper.populateLoggingDetail(err, {
                session: props.session.toJS()
              })
            )
          );
        }

        consoleLogEnabled && console.error('passwordReset Error: ', err);
      });
    }
    else {
      setResetPwdError(t('userform.pwdreset.error.no_redirecturl'));
      setShowResetPwd(false);
    }
  }

  const disableTFA = (id) => {
    setBusy(false);
    setDisableTFASuccess(false);
    setDisableTFAError('');
    
    if(!Helper.stringHasValue(id)) {
      setDisableTFAError(t('userform.disabletfa.error.general'));
      setShowDisableTFA(false);
      return;
    }

    setBusy(true);
    Api.userDisableTFA(id)
    .then(_ => {
      gridRef.current?.reload();
      let user = Helper.deepCopy(selectedUser);
      user.tfa_secret = null;
      setSelectedUser(user);
      setDisableTFASuccess(true);
      setShowDisableTFA(false);
      setBusy(false);
    })
    .catch(err => {
      setDisableTFAError(err.message);
      setShowDisableTFA(false);
      setBusy(false);
      if(logEnabled) {
        err.message += ' - Api.userDisableTFA Error @ /system/usermgt.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(err, {
              session: props.session.toJS()
            })
          )
        );
      }

      consoleLogEnabled && console.error('disableTFA Error: ', err);
    });

  }

  const userNewFabOnClick = (event) => {
    if(useMgtConfig?.value?.allow_create && useMgtConfig?.value?.allow_invite) {
      // If both invite and create are allow, show the two fab buttons
      setFabAnchorEl(event.currentTarget)
    }
    else {
      if(useMgtConfig?.value?.allow_create) {
        // Show new user form
        userCreateFabOnClick();
      }
      

      if(useMgtConfig?.value?.allow_invite) {
        // Show the invite dialog
        userInviteFabOnClick();
      }
    }
  }

  const userCreateFabOnClick = () => {
    setSelectedUser(
      {
        "id": "",
        "avatar": "",
        "description": null,
        "email": "",
        "email_notifications": true,
        "external_identifier": null,
        "first_name": "",
        "language": "",
        "last_name": "",
        "location": "",
        "password": "",
        "role": "",
        "status": "active",
        "theme": "auto",
        "title": ""
      }
    );
    setUserValidation(userValidationInitState);
    setUserFormMode('edit');
    setFabAnchorEl(null);
  }

  const userInviteFabOnClick = () => {
    setUserInviteValidation(userInviteValidationInitState);
    setShowUserInvite(true);
    userInviteFormRef?.current && userInviteFormRef.current.reset();
    setFabAnchorEl(null);
  }
  
  const closeUserInvite = () => {
    setUserInviteValidation(userInviteValidationInitState);
    setShowUserInvite(false);
    userInviteFormRef?.current && userInviteFormRef.current.reset();
  }

  const sendUserInvite = async () => {
    setInviteStatus(0);
    try {
      let inviteURL = Helper.carefullyGetValue(appDetail, ['url'], '');
      let valid = Helper.validateFormRef(userInviteFormRef, userInviteFields, userInviteValidation, setUserInviteValidation, t);
      if(valid) {
        setBusy(true);
        
        let email = Helper.carefullyGetValue(userInviteFormRef, 'current.elements.email.value'.split('.'), '');
        let role = Helper.carefullyGetValue(userInviteFormRef, 'current.elements.role.value'.split('.'), '');

        await Api.userInvite(email, role, `${inviteURL}acceptinvite`);
        setBusy(false);
        setInviteStatus(1);
        userInviteFormRef?.current && userInviteFormRef.current.reset();
        setShowUserInvite(false);
      }
    } catch (error) {
      setBusy(false);
      if(Helper.arrayHasItem(error.errors) && error.errors.filter(item => item.extensions?.code === 'RECORD_NOT_UNIQUE').length > 0) {
        setInviteStatus(3);
        // No logging for RECORD_NOT_UNIQUE
      }
      else {
        setInviteStatus(2);
        // Only log for general error
        if(logEnabled) {
          error.message += ' - sendUserInvite Error @ /system/usermgt.js';
          props.dispatch(
            loggingActions.loggingNew(
              Helper.populateLoggingDetail(error, {
                session: props.session.toJS()
              })
            )
          );
        }
        consoleLogEnabled && console.error('sendUserInvite Error: ', JSON.stringify(error));
      }
    }
  }

  return (
    <Layout busy={busy}>
      <div className={commonClasses.flexFullColumn}>
        <ReactDataGrid
          idProperty="id"
          onReady={ref => {
            setGridRef(ref);
            ref?.current && ref?.current.focus();
          }}
          theme={dataGridTheme}
          style={gridStyle}
          showActiveRowIndicator={true}
          defaultActiveIndex={0}
          activateRowOnFocus={true}
          enableKeyboardNavigation={true}
          columns={columns}
          dataSource={dataSource}
          defaultFilterValue={filterValue}
          onFilterValueChange={setFilterValue}
          pagination
          i18n={UIHelper.reactDataGridI18n(t)}
          showColumnMenuGroupOptions={false}
          showColumnMenuLockOptions={false}
          enableColumnAutosize={false}
          defaultLimit={isMobile ? 10 : 20}
          onRowClick={(rowProps, event) => openSelectedUser(rowProps.data)}
          onKeyDown={onGridKeyDown}
        />
        {
          !Helper.isNotNullAndUndefined(selectedUser) && (useMgtConfig?.value?.allow_create || useMgtConfig?.value?.allow_invite) &&
          <Fab color="primary" aria-label="add" 
            onClick={userNewFabOnClick}
            className={commonClasses.fab}
          >
            <MUIIcons.Add />
          </Fab>
        }
        <Zoom in={Helper.isNotNullAndUndefined(selectedUser)}>
          <Paper variant={'elevation'} elevation={4}
            className={commonClasses.overlayForm}
          >
            <Toolbar title={`${Helper.carefullyGetValue(selectedUser, ['first_name'], Helper.carefullyGetValue(selectedUser, ['last_name'], ''))}${t('userform.formtitle')}`} 
              className={isMobile ? undefined : commonClasses.toolbarFloatingContainer}
              showIcon={true}
              iconComp={
                Helper.isNotNullAndUndefined(selectedUser) ?
                <>
                  {
                    Helper.stringHasValue(selectedUser.avatar) ?
                    <Avatar alt="" src={`${process.env.GATSBY_API_URL}assets/${selectedUser.avatar}?access_token=${auth.data?.access_token}&width=50&height=50&fit=cover`} />
                    :
                    <MUIIcons.AccountCircle />
                  }
                </>
                :
                <MUIIcons.AccountCircle />
              }
              showCloseButton={false}
              customCtrl={
                isMobile ?
                null
                :
                <div> 
                  {/* Show action button within Toolbar when not in mobile device */}
                  {toolbarActionButtons()}
                </div>
              }
            />
            {
              // Show action buttons seperate from toolbar in mobile device
              isMobile &&
              <div className={commonClasses.toolbarFloatingActionButtonContainer}>
                {toolbarActionButtons()}
              </div>
            }
            <div className={clsx(commonClasses.flexColumn, commonClasses.defaultPadding)} style={{marginTop: 6}}>
              {
                isMobile &&
                <>
                  {userFormPhoto}
                  {userFormUI()}
                </>
              }
              {
                !isMobile &&
                <div className={clsx(commonClasses.flexFullRow)}>
                  <div style={{flex: 6}}>
                    {userFormUI()}
                  </div>
                  {userFormPhoto}
                </div>
              }
            </div>
            
          </Paper>
        </Zoom>
      
      </div>
      <Popover
        id={'fab_popover'}
        open={Helper.isNotNullAndUndefined(fabAnchorEl)}
        anchorEl={fabAnchorEl}
        onClose={_ => setFabAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        elevation={0}
        classes={{
          paper: commonClasses.transparent
        }}
      >
        <div className={commonClasses.flexColumn} 
          style={{
            padding: 6,
            rowGap: 10
          }}
        >
          <Fab variant="extended" color="secondary" aria-label="add" onClick={userCreateFabOnClick}>
            <MUIIcons.Create />
            {t('button.create')}
          </Fab>
          <Fab variant="extended" color="secondary" onClick={userInviteFabOnClick}>
            <MUIIcons.Mail />
            {t('button.invite')}
          </Fab>
        </div>
      </Popover>

      {/* User Form Update Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={updateStatus !== 0}
        onClose={_ => setUpdateStatus(0)}
        key={'userUpdateStatusSnackbar'}
      >
        {
          updateStatus !== 0 ?
          <Alert onClose={_ => setUpdateStatus(0)} severity={updateStatus === 1 ? 'success' : 'error'}>
            {t(`userform.updatestatus.${updateStatus === 1 ? 'success' : 'failed'}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Delete User Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={deleteStatus !== 0}
        onClose={_ => setDeleteStatus(0)}
        key={'userDeleteStatusSnackbar'}
      >
        {
          deleteStatus !== 0 ?
          <Alert onClose={_ => setUpdateStatus(0)} severity={deleteStatus === 1 ? 'success' : 'error'}>
            {t(`userform.deletestatus.${deleteStatus === 1 ? 'success' : 'failed'}`)}
          </Alert>
          :
          null
        }
      </Snackbar>
      
      {/* No Access Right Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={showNoRightWarning}
        onClose={_ => setShowNoRightWarning(false)}
        key={'userUpdateWarningSnackbar'}
      >
        <Alert severity={'warning'} onClose={_ => setShowNoRightWarning(false)}>
          {t('userform.warning.noright')}
        </Alert>
      </Snackbar>
      
      {/* Password Reset Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={resetPwdSent}
        onClose={_ => setResetPwdSent(false)}
        key={'userResetPwdSuccessSnackbar'}
      >
        <Alert onClose={_ => setResetPwdSent(false)} severity={'success'}>
          {t(`userform.pwdreset.success`)}
        </Alert>
      </Snackbar>
      
      {/* Password Reset Error Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={Helper.stringHasValue(resetPwdError)}
        onClose={_ => setResetPwdError('')}
        key={'userResetPwdErrorSnackbar'}
      >
        <Alert severity={'error'} onClose={_ => setResetPwdError('')}>
          {resetPwdError}
        </Alert>
      </Snackbar>

      {/* Disable TFA Success Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={disableTFASuccess}
        onClose={_ => setDisableTFASuccess(false)}
        key={'userDisableTFASuccessSnackbar'}
      >
        <Alert onClose={_ => setDisableTFASuccess(false)} severity={'success'}>
          {t(`userform.disabletfa.success`)}
        </Alert>
      </Snackbar>
      
      {/* Disable TFA Error Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={Helper.stringHasValue(disableTFAError)}
        onClose={_ => setDisableTFAError('')}
        key={'userDisableTFAErrorSnackbar'}
      >
        <Alert severity={'error'} onClose={_ => setDisableTFAError('')}>
          {disableTFAError}
        </Alert>
      </Snackbar>
      
      {/* User Invite Status Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={inviteStatus !== 0}
        onClose={_ => setInviteStatus(0)}
        key={'userInviteStatusSnackbar'}
      >
        {
          inviteStatus !== 0 ?
          <Alert onClose={_ => setInviteStatus(0)} severity={inviteStatus === 1 ? 'success' : 'error'}>
            {t(`user.invitestatus.${inviteStatus === 1 ? 'success' : (inviteStatus === 3 ? 'existed' : 'failed')}`)}
          </Alert>
          :
          null
        }
      </Snackbar>

      {/* Password Reset Dialog */}
      <Dialog
        open={showResetPwd}
        TransitionComponent={Transition}
        keepMounted
        onClose={_ => setShowResetPwd(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          <Typography variant="h6">
            {t('dialog.resetpwd.title')}
          </Typography>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description">
          <Typography variant='body1'>
            {t('dialog.resetpwd.desc')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={_ => setShowResetPwd(false)}
          >
            {t('button.cancel')}
          </Button>
          <Button
            color='primary'
            onClick={_ => passwordReset(selectedUser?.email)}
          >
            {t('button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* TFA Disable Dialog */}
      <Dialog
        open={showDisableTFA}
        TransitionComponent={Transition}
        keepMounted
        onClose={_ => setShowDisableTFA(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          <Typography variant="h6">
            {t('dialog.disabletfa.title')}
          </Typography>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description">
          <Typography variant='body1'>
            {t('dialog.disabletfa.desc')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={_ => setShowDisableTFA(false)}
          >
            {t('button.cancel')}
          </Button>
          <Button
            color='primary'
            onClick={_ => disableTFA(selectedUser?.id)}
          >
            {t('button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Invite Dialog */}
      <Dialog
        open={showUserInvite}
        TransitionComponent={Transition}
        keepMounted={false} // force unmount when dialog is dismissed. This is to force the form to reset the DDL
        onClose={closeUserInvite}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <form ref={userInviteFormRef} 
          onSubmit={(event) => {
            sendUserInvite();
            event.preventDefault();
          }}
        >
          <DialogTitle id="classic-modal-slide-title" disableTypography>
            <Typography variant="h6">
              {t('dialog.userinvite.title')}
            </Typography>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description">
            <div className={clsx(commonClasses.flexFullColumn)} style={{rowGap: 20}}>
              <Typography variant='body1'>
                {t('dialog.userinvite.desc')}
              </Typography>
              <TextField id="email" label={t('userform.email')} 
                variant="outlined" 
                error={userInviteValidation.email.error}
                helperText={userInviteValidation.email.message}
                onBlur={(event) => Helper.validateFormField('email', event.target.value, userInviteFields, userInviteValidation, setUserInviteValidation, t)}
              />
              
              <DropDownList id="role" 
                variant="outlined"  
                style={{flex: 1, minWidth: 200, marginBottom: 10}}
                labelText={t('userform.role')}
                defaultValue={''}
                options={
                  sysRoles
                  .sort((a, b) => (a.value?.level > b.value?.level ? -1 : (a.value?.level < b.value?.level ? 1 : 0)))
                  .filter(item => item.value?.level >= myRoleLevel)
                  .map(item => ({code: item.role, text: UIHelper.getRoleText(item.role, sysRoles, locale)}))
                }
                error={userInviteValidation.role.error}
                helperText={userInviteValidation.role.message}
              />
          </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeUserInvite}>
              {t('button.cancel')}
            </Button>
            <Button color='primary' type='submit'>
              {t('button.invite')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Delete User Dialog */}
      <Dialog
        open={showUserDelete}
        classes={{
          paper: commonClasses.alert_DialogBoxContainer
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={_ => setShowUserDelete(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle id="classic-modal-slide-title" disableTypography>
          <Typography variant="h6">
            {t('dialog.deleteuser.title')}
          </Typography>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description">
          <Typography variant='body1'>
            {t('dialog.deleteuser.desc')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={_ => setShowUserDelete(false)}
          >
            {t('button.cancel')}
          </Button>
          <Button
            //color='secondary'
            onClick={_ => deleteUser()}
          >
            {t('button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Help Screen */}
      <HelpScreen
        open={showHelp}
        onClose={_ => setShowHelp(false)}
        title={t('dialog.help.title')}
      >
        <Typography variant='body1' style={{marginBottom: 20}}>
          {t('dialog.help.desc')}
        </Typography>
        <Typography variant='body1' style={{marginBottom: 20}}>
          {t('dialog.help.functions').replace(/{modifier}/g, UIHelper.getModifierKey())}
        </Typography>
        <MuiMarkdown>
          {t('dialog.help.keys').replace(/{modifier}/g, UIHelper.getModifierKey())}
        </MuiMarkdown>
      </HelpScreen>
    </Layout>
  );
}

export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(UserMgtPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
